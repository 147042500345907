/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

/* Modify all XMLHttpRequests to have a hard coded timeout to avoid issues with
 either platform specific timeouts or library request timeouts not being set, or being set to very long times. */
window.XMLHttpRequest = new Proxy(XMLHttpRequest, {
    construct: function(target, args) {
        const xhr = new target(...args);
        xhr.timeout = 8000;
        xhr.ontimeout = () => console.error('Request timeout');
        return xhr;
    }
});
